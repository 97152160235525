import { getFormattedAmount } from 'frontend-toolkit';
import React from 'react';
import { usePaymentStateSelector } from 'state/payment/paymentSlice';

export type PaymentTypeOption = 'card' | 'us_bank_account'; // the values from stripe form

export const usePaymentHelpers = () => {
  const [subtotal, setSubtotal] = React.useState<string | null>(null);
  const [showFee, setShowFee] = React.useState<boolean>(false);

  const paymentData = usePaymentStateSelector();

  React.useEffect(() => {
    // Check for not null because amount and fee can be 0
    if (paymentData?.amount != null) {
      setSubtotal(getFormattedAmount(+paymentData.amount / 100));
    }
    if (!paymentData?.paymentAccountPaysProcessingFee) {
      setShowFee(true);
    }
  }, [paymentData]);

  const getTotal = React.useCallback(
    (paymentType?: PaymentTypeOption) => {
      const fee = paymentType === 'us_bank_account' ? paymentData?.achFee || 0 : paymentData?.creditCardFee || 0;
      if (paymentData?.amount != null) {
        return getFormattedAmount((+paymentData?.amount + fee) / 100);
      }
    },
    [paymentData?.amount, paymentData?.achFee, paymentData?.creditCardFee]
  );

  const getFee = React.useCallback(
    (paymentType?: PaymentTypeOption) => {
      const fee = paymentType === 'us_bank_account' ? paymentData?.achFee || 0 : paymentData?.creditCardFee || 0;
      return getFormattedAmount(fee / 100);
    },
    [paymentData?.achFee, paymentData?.creditCardFee]
  );

  return {
    getTotal,
    subtotal,
    getFee,
    showFee,
  };
};
