import { Icon, ModalContainer } from '@teamsnap/snap-ui';
import { Button } from '@teamsnap/teamsnap-ui';
import { PaymentExpirationModal } from 'components/PaymentExpirationModal/PaymentExpirationModal';
import { Accordion } from 'components/shared/Accordion';
import { ActionContainer } from 'components/shared/ActionContainer';
import { Header } from 'components/shared/Header/Header';
import { REGISTRATION_URL } from 'core/constants';
import ENVIRONMENT from 'core/environment';
import { FeatureFlagConfig, FeatureFlags } from 'core/feature-flags';
import { InsuranceOfferState } from 'core/types';
import { FeatureFlagService } from 'frontend-toolkit';
import React from 'react';
import { useAppNavigate } from 'state/hooks';
import { useInstallmentPreviewStateSelector } from 'state/installments/installmentsSlice';
import { useFormIdSelector, useFormStateSelector, useOrderStateSelector, useOrderWaitlistStatus, useOrgIdSelector } from 'state/order/orderSlice';
import { useUserSelector } from 'state/user/userSlice';
import { CountDownExpirationBanner } from '../CountDownExpirationBanner';
import { AddonDisplay } from './AddonDisplay/AddonDisplay';


const Addons = () => {
  const user = useUserSelector();
  const formId = useFormIdSelector();
  const isWaitlistedOrder = useOrderWaitlistStatus();
  const navigate = useAppNavigate();
  const [hasCountDownEnded, setHasCountDownEnded] = React.useState(false);
  const installmentPreviewData = useInstallmentPreviewStateSelector();
  const organizationId = useOrgIdSelector();
  const orderState = useOrderStateSelector();
  const formState = useFormStateSelector();
  const [addonOfferState, setAddonOfferState] = React.useState<InsuranceOfferState[][]>([]);
  const [errorModalOpen, setErrorModalOpen] = React.useState(false);
  const [addonErrorCount, setAddonErrorCount] = React.useState(0);

  // TODO: Remove Feature Flag once feature is fully released.
  const capacityLimitsAreEnabled = FeatureFlagService.isFeatureEnabledForOrg(
    FeatureFlagConfig[FeatureFlags.PARTICIPANT_GROUP_CAPACITY_LIMIT],
    ENVIRONMENT,
    organizationId || undefined
  );

  const handleBackButton = () => {
    if (installmentPreviewData && installmentPreviewData.length > 0) {
      navigate(`/order/${orderState?.id}/installments`);
      return;
    }
    if (formId) {
      window.location.href = `${REGISTRATION_URL}/form/${formId}/cart`;
    }
  };

  const onSubmit = () => {
    /* 
      Offer state is an array of arrays: 

      - Insurance Type Component (e.g., gap medical )
        - {selectedState, quote}
        - {selectedState, quote}
      - Insurance Type Component (e.g., registration protection) 
        - {selectedState, quote}
        - {selectedState, quote} 
      
      For each component, we want to check if its in declined state (e.g. all quotes were DECLINED), 
      accepted (e.g., some or all quotes are ACCEPTED), or unknown (e.g. there are some quotes with UNKNOWN)

      Unkown states open an error message for the user when trying to navigate to the next step in the checkout
    */
    const isInvalid = addonOfferState.some((componentState: InsuranceOfferState[]) => {
      return componentState ? componentState.every((componentStateItem: InsuranceOfferState) => componentStateItem.selectedState === 'UNKNOWN') : false;
    })

    if (isInvalid) {
      setErrorModalOpen(true);
      return;
    }

    navigate(`/order/${orderState?.id}/payment`);
  }

  const updateAddonOfferState = (index: number, state: InsuranceOfferState[]) => {
    const updatedState = [...addonOfferState];
    updatedState[index] = state;
    setAddonOfferState(updatedState);
  }

  const handleEmptyOfferState = () => {
    setAddonErrorCount((prev) => prev + 1);
  }

  React.useEffect(() => {
    /* 
      the emptyOfferState events are fired from the Vertical Insure MultiOfferWrapperElementComponent
      components exactly once if the component cannot load any quotes (either the service is down, or the 
      quote for the data cannot be generated). 

      We load the MultiOfferWrapperElementComponent for every availableAddon so if there are 2 add-ons
      and they both result in an error then addonErrorCount would be 2 which will result in skipping 
      the add-ons page and continuing to the payment page.
    */

    if (formState?.availableAddons && addonErrorCount >= formState?.availableAddons?.length) {
      navigate(`/order/${orderState?.id}/payment`);
      return;
    }
  }, [addonErrorCount, formState?.availableAddons, orderState?.id])

  return (
    <ActionContainer
      submitting={false}
      removeContentFormatting={true}
      header={
        <Header
          title="Checkout - Add-ons"
          navigation={
            <Button
              iconPosition="left"
              mods="back-button sui-m-0 sui-w-auto sui-text-gray-10 t:sui-hidden"
              icon="arrow-left"
              type="link"
              size="large"
              testId="back-button"
              onClick={handleBackButton}
            />
          }
          profileName={`${user?.firstName} ${user?.lastName}`}
        />
      }
      footer={
        <div className="t:sui-flex t:items-center t:sui-justify-between">
          <Button
            mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1 sui-hidden t:sui-flex"
            icon="arrow-left"
            iconPosition="left"
            testId="back-button"
            onClick={handleBackButton}
          >
            Back
          </Button>
          <Button
            key="check-out"
            color="primary"
            mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
            iconPosition="right"
            onClick={onSubmit}
            isDisabled={hasCountDownEnded}
            testId="submit-addons-button"
          >
            Next
          </Button>
        </div>
      }
      children={
        <div data-testid="addons-view" className="sui-px-1">
          {capacityLimitsAreEnabled && orderState?.checkoutStartTime && !isWaitlistedOrder && (
            <CountDownExpirationBanner
              serverTime={orderState.currentMillis}
              checkoutStartDate={new Date(orderState.checkoutStartTime).toUTCString()}
              isCountDownEnded={hasCountDownEnded}
              onFinishCountDown={() => setHasCountDownEnded(true)}
            />
          )}

          {capacityLimitsAreEnabled && (
            <PaymentExpirationModal
              isOpen={hasCountDownEnded}
              onClose={() => {
                window.location.href = `${REGISTRATION_URL}/form/${formId}/cart`;
              }}
            />
          )}

          <ModalContainer
            isOpen={errorModalOpen}
            onClose={() => setErrorModalOpen(false)}
          >
            <div className='sui-flex sui-flex-col sui-mx-2 sui-items-center'>
              <Icon
                filled
                name="report"
                size="xl"
                className="sui-text-negative-icon"
              />
              <div className='sui-mt-1 sui-body sui-text-center'>
                To continue, select an option or decline Registration Cancellation Protection and Gap Medical Insurance.
              </div>
            </div>
          </ModalContainer>

          {formState?.availableAddons?.map((addon, index) => (
            <Accordion title={addon.offering.productName} key={addon.offering.productName + index} defaultOpen={true}>
              <div className='sui-pt-3 sui-relative' data-testid='addon-display-wrapper'>
                <AddonDisplay formPartnerOffering={addon} offerStateChange={(state: InsuranceOfferState[]) => updateAddonOfferState(index, state)} emptyOfferError={handleEmptyOfferState} />
              </div>
            </Accordion>
          ))}

        </div>
      }
    />
  );
}

export default Addons;